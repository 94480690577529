//
// Footer
//

/* footer list style */
.footer-list-style {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding-bottom: 0.5rem;

    a {
      color: $gray-800;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.2;

      &:hover {
        color: $primary;
      }
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

/* footer list style */
.footer-list-style-two {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding-bottom: 0.875rem;

    a {
      color: $gray-600;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.2;

      &:hover {
        color: $primary;
      }
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}


/* footer title style2 */
.footer-title-style2 {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    background: $primary;
    width: 60px;
    height: 2px;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

/* footer app buttons */
.app-btn {
  .media i {
    font-size: 20px;
  }

  border: 1px solid rgba($white, $opacity9);

  .media-body span {
    font-size: 12px;
  }
}



