//
// Accordion
//

.accordion > .card {
  overflow: hidden;
  border: none;

  &:not(:first-of-type) {
    .card-header:first-child {
      @include border-radius(0);
    }

    &:not(:last-of-type) {
      border-bottom: 0;
      @include border-radius(0);
    }
  }

  &:first-of-type {
    border-bottom: 0;
    @include border-bottom-radius(0);
  }

  &:last-of-type {
    @include border-top-radius(0);

    .card-header {
      margin-bottom: -1px;
    }

    .collapse.show {
      border-bottom: none;
    }
  }

  .collapse.show {
    border-bottom: 1px solid $card-border-color;
  }

  .card-header {
    background: none;
    padding: 0;
    margin: 0;
  }

  .btn-link {
    padding: 1.5rem 2rem 1.5rem 0;
    width: 100%;
    text-align: left;
    position: relative;

    &.collapsed:after {
      background: none;
      content: "+";
      right: 15px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      top: 0;
      bottom: 0;
      margin: auto 0;
      height: 34px;
    }

    &:after {
      background: none;
      content: "-";
      right: 17px;
      left: inherit;
      font-size: 20px;
      height: auto;
      transform: none;
      width: auto;
      position: absolute;
      color: $primary;
      top: 0;
      bottom: 0;
      margin: auto 0;
      height: 34px;
    }
  }
}