//
// Bootstrap Tab overrides
//

.custom-file {
  height: 45px;
}

.custom-file-input {
  height: 100%;
}