//
// Navigation
//

.navbar-brand {
  width: 8rem;
}

.dropdown-submenu {
  position: relative;

  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }

  .dropdown-toggle {
    position: relative;

    &:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-size: 80%;
      font-weight: 900;
      position: absolute;
      right: 23px;
      top: 12px;
    }
  }
}

.dropdown-toggle:after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f107";
  margin-left: 0.5rem;
  border: none;
  vertical-align: middle;
}

.mega-dropdown-menu {
  min-width: 42rem;

  .dropdown-item {
    padding: 0.5rem 0;
  }
}

.navbar-nav .dropdown-img {
  background: no-repeat center center/cover;
  padding: 2.375rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($primary,.85);
    border-radius: inherit;
    z-index: 1;
  }
}


@include media-breakpoint-down(md) {

  .mega-dropdown-menu {
    min-width: auto;
  }

  .dropdown-toggle:after {
    float: right;
    margin-top: 3px;
    transform-origin: center center;
  }

  .navbar-nav {
    .dropdown-toggle[aria-expanded=true]:after {
      transform-origin: center center;
      transform: rotate(180deg);
      margin-top: 1px;
    }

    .dropdown-menu {
      box-shadow: none !important;
      padding: 0 0 0.3rem 0;

      .dropdown-menu {
        padding: 0.2rem 0.5rem 0.3rem 0.5rem;
      }
    }
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 0.3rem 0.5rem;
      font-size: 0.95rem;
      white-space: normal;
    }

    .dropdown-menu .dropdown-item {
      font-size: 0.9rem;
    }
  }

  .dropdown-submenu {
    .dropdown-item.active:after {
      transform: rotate(90deg);
    }

    .dropdown-toggle:after {
      top: 4px;
      right: 10px;
    }
  }

}

@include media-breakpoint-up(lg) {

  .navbar-nav li.dropdown > .mega-dropdown-menu {
    left: 100%;
  }

}

@include media-breakpoint-up(xl) {

  .navbar-nav li.dropdown > .mega-dropdown-menu {
    left: 50%;
  }
  
}

