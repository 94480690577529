/**
 * Cavistar | Multipurpose Responsive Template
 * Theme helper styles
 */

.bg-cover {
  background: no-repeat center center/cover;
}

.sm-avatar {
  width: 2.75rem;
  height: 2.75rem;
}

.md-avatar {
  width: 3.75rem;
  height: 3.75rem;
}

.max-width-content {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -o-max-content;
  display: inline-block;
  max-width: 100%;
}

/*positions*/
.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

/* z-index */
.z-index-n9 {
  z-index: -9;
  position: relative;
}

.z-index-9 {
  z-index: 9;
  position: relative;
}

.z-index-99 {
  z-index: 99;
  position: relative;
}

/*overflow*/
.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

/* hover styles */
.hover-white:hover {
  color: $white;
  @include transition-animation;
}

.hover-opacity:hover {
  opacity: 1;
  @include transition-animation;
}

.hover-primary:hover {
  color: $primary;
  @include transition-animation;
}

/* background color and opacity */
.bg-transparent {
  background: transparent !important;
}

.bg-white-10 {
  background: rgba($white, 0.1);
}

.bg-primary-10 {
  background: rgba($primary, 0.1);
}

.bg-primary-20 {
  background: rgba($primary, 0.2);
}

.bg-primary-30 {
  background: rgba($primary, 0.3);
}

.bg-primary-40 {
  background: rgba($primary, 0.4);
}

.bg-primary-50 {
  background: rgba($primary, 0.5);
}

.bg-primary-60 {
  background: rgba($primary, 0.6);
}

.bg-primary-70 {
  background: rgba($primary, 0.7);
}

.bg-primary-80 {
  background: rgba($primary, 0.8);
}

.bg-primary-90 {
  background: rgba($primary, 0.9);
}

.bg-danger-10 {
  background: rgba($danger, 0.1);
}

.bg-danger-20 {
  background: rgba($danger, 0.2);
}

.bg-danger-30 {
  background: rgba($danger, 0.3);
}

.bg-danger-40 {
  background: rgba($danger, 0.4);
}

.bg-danger-50 {
  background: rgba($danger, 0.5);
}

.bg-danger-60 {
  background: rgba($danger, 0.6);
}

.bg-danger-70 {
  background: rgba($danger, 0.7);
}

.bg-danger-80 {
  background: rgba($danger, 0.8);
}

.bg-danger-90 {
  background: rgba($danger, 0.9);
}

/* overlay */
.overlay-gradient:before {
  background: rgba(0, 0, 0, 0.76);
  background: -webkit-linear-gradient(top, transparent, #000 116%);
  background: linear-gradient(-180deg, transparent, #000 116%);
}

.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-primary:before {
  background-color: $primary;
}

.overlay-black:before {
  background-color: $black;
}

.overlay-white:before {
  background-color: $white;
}

.overlay-warning:before {
  background-color: $warning;
}

.overlay-10:before {
  opacity: $opacity1;
}

.overlay-20:before {
  opacity: $opacity2;
}

.overlay-30:before {
  opacity: $opacity3;
}

.overlay-40:before {
  opacity: $opacity4;
}

.overlay-50:before {
  opacity: $opacity5;
}

.overlay-60:before {
  opacity: $opacity6;
}

.overlay-70:before {
  opacity: $opacity7;
}

.overlay-75:before {
  opacity: $opacity75;
}

.overlay-80:before {
  opacity: $opacity8;
}

.overlay-85:before {
  opacity: $opacity85;
}

.overlay-90:before {
  opacity: $opacity9;
}

.overlay-95:before {
  opacity: $opacity95;
}


/* opacity */
.opacity1 {
  opacity: $opacity1;
}

.opacity2 {
  opacity: $opacity2;
}

.opacity3 {
  opacity: $opacity3;
}

.opacity4 {
  opacity: $opacity4;
}

.opacity5 {
  opacity: $opacity5;
}

.opacity6 {
  opacity: $opacity6;
}

.opacity7 {
  opacity: $opacity7;
}

.opacity75 {
  opacity: $opacity75;
}

.opacity8 {
  opacity: $opacity8;
}

.opacity85 {
  opacity: $opacity85;
}

.opacity9 {
  opacity: $opacity9;
}

.opacity95 {
  opacity: $opacity95;
}  

/*SVG fill colors*/
.fill-primary {
  fill: $primary;
}

.fill-white {
  fill: $white;
}

.fill-light {
  fill: $gray-100;
}

.fill-none {
  fill: none;
}

.fill-dark {
  fill: $gray-800;
} 

/* text colors */
.text-black-60 {
  color: rgba($black, 0.6);
}

.text-black-70 {
  color: rgba($black, 0.7);
}

.text-black-75 {
  color: rgba($black, 0.75);
}

.text-black-80 {
  color: rgba($black, 0.8);
}

.text-black-85 {
  color: rgba($black, 0.85);
}

.text-black-90 {
  color: rgba($black, 0.9);
}

.text-black-95 {
  color: rgba($black, 0.95);
}

.text-white-60 {
  color: rgba($white, 0.6);
}

.text-white-70 {
  color: rgba($white, 0.7);
}

.text-white-75 {
  color: rgba($white, 0.75);
}

.text-white-80 {
  color: rgba($white, 0.8);
}

.text-white-85 {
  color: rgba($white, 0.85);
}

.text-white-90 {
  color: rgba($white, 0.9);
}

.text-white-95 {
  color: rgba($white, 0.95);
}

/*line-height*/
.line-height-base {
  line-height: $line-height-base !important;
}

.line-height-lg {
  line-height: $line-height-lg !important;
}

.line-height-sm {
  line-height: $line-height-sm !important;
}


/*shadow*/
.shadow {
  box-shadow: 0px 0px 30px rgba(83, 88, 93, 0.15) !important;
}

.shadow-light {
  box-shadow: 0px 0px 10px rgba(83, 88, 93, 0.1);
}

.no-shadow {
  box-shadow: none !important;
}

.shadow-hover {
  @include transition-animation;

  &:hover {
    box-shadow: 0px 0px 50px rgba(83, 88, 93, 0.1);
  }
}

.shadow-hover-1:hover {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
}

    
/*border and border radius*/
.border-light {
  border-color: rgba(0, 0, 0, 0.06) !important;
}

.border-radius-3 {
  border-radius: $border-radius !important;
}

.border-radius-before {
  border-radius: $border-radius !important;

  &:before {
    border-radius: $border-radius !important;
  }
}

.border-radius-left-0 {
  border-radius: 0px 3px 3px 0px !important;
}

.border-radius-right-0 {
  border-radius: 3px 0px 0px 3px !important;
}

.border {
  border: 1px solid $border-color !important;
}

.border-right-0 {
  border-right: 0;
}

.border-left-0 {
  border-left: 0;
}

/*border style extend*/
@include media-breakpoint-up(sm) {
  .border-sm-right {
    border-right: $border-width solid $border-color !important;
  }

  .border-sm-left {
    border-left: $border-width solid $border-color !important;
  }
}

@include media-breakpoint-up(md) {
  .border-md-right {
    border-right: $border-width solid $border-color !important;
  }

  .border-md-left {
    border-left: $border-width solid $border-color !important;
  }
}

@include media-breakpoint-up(lg) {
  .border-lg-right {
    border-right: $border-width solid $border-color !important;
  }

  .border-lg-left {
    border-left: $border-width solid $border-color !important;
  }
}

@include media-breakpoint-up(xl) {
  .border-xl-right {
    border-right: $border-width solid $border-color !important;
  }

  .border-xl-left {
    border-left: $border-width solid $border-color !important;
  }
}


/* height */
.h-300 {
  height: 300px;
}

.h-350 {
  height: 350px;
}

.h-400 {
  height: 400px;
  height: 400px;
}

.h-100vh {
  height: 100vh;
}

.min-height-50vh {
  min-height: 50vh;
}

@include media-breakpoint-up(lg) {
  .h-lg-400 {
    height: 400px;
  }

  .h-lg-500 {
    height: 500px;
  }

  .min-height-lg-100vh {
    min-height: 100vh;
  }

  .min-height-lg-90vh {
    min-height: 90vh;
  }

  .min-height-lg-80vh {
    min-height: 80vh;
  }

  .min-height-lg-70vh {
    min-height: 70vh;
  }

  .min-height-lg-60vh {
    min-height: 60vh;
  }

  .min-height-lg-50vh {
    min-height: 50vh;
  }

  .min-height-lg-40vh {
    min-height: 40vh;
  }

  .min-height-lg-35vh {
    min-height: 35vh;
  }

  .min-height-lg-30vh {
    min-height: 30vh;
  }
}

@include media-breakpoint-up(xl) {
  .h-xl-400 {
    height: 400px;
  }

  .h-xl-500 {
    height: 500px;
  }
}


/* width */
 .vw-50 {
  width: 50vw;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

@include media-breakpoint-up(sm) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }
}

@include media-breakpoint-up(lg) {
  .vw-lg-50 {
    width: 50vw;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }
}


/*display font size extend*/
.display-5 {
  @include font-size($display5-size);
  font-weight: $display5-weight; 
}

.display-6 {
  @include font-size($display6-size);
  font-weight: $display6-weight;
}

.display-7 {
  @include font-size($display7-size);
  font-weight: $display7-weight;
}

.display-8 {
  @include font-size($display8-size);
  font-weight: $display8-weight; 
}

.display-9 {
  @include font-size($display9-size);
  font-weight: $display9-weight;
}

.display-10 {
  @include font-size($display10-size);
  font-weight: $display10-weight;
}

.display-11 {
 @include font-size($display11-size);
  font-weight: $display11-weight; 
}

.display-12 {
  @include font-size($display12-size);
  font-weight: $display12-weight;
}

